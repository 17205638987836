<script>
export default {
    name: 'BaseInputNumber',
};
</script>

<script setup>
import { debounce, isNaN } from 'lodash';
import { computed } from 'vue';
import BaseInputIconDown from '@/components/base-components/components/input/assets/icons/down';
import BaseInputIconMinus from '@/components/base-components/components/input/assets/icons/minus';
import BaseInputIconPlus from '@/components/base-components/components/input/assets/icons/plus';
import BaseInputIconUp from '@/components/base-components/components/input/assets/icons/up';
import { SIZE, CONTROLS_POSITION, TYPE } from '@/components/base-components/components/input';
import { useRepeat } from '@/components/base-components/composables/repeat';

const props = defineProps({
    value:            {
        type: Number,
    },
    name:             {
        type: String,
    },
    placeholder:      {
        type: String,
    },
    type:             {
        type:      String,
        default:   TYPE.DEFAULT,
        validator: value => Object.values(TYPE).includes(value),
    },
    size:             {
        type:      String,
        default:   SIZE.MEDIUM,
        validator: value => Object.values(SIZE).includes(value),
    },
    step:             {
        type:    Number,
        default: 1,
    },
    stepStrictly:     {
        type:    Boolean,
        default: false,
    },
    max:              {
        type:    Number,
        default: Infinity,
    },
    min:              {
        type:    Number,
        default: -Infinity,
    },
    noControls:       {
        type: Boolean,
    },
    controlsPosition: {
        type:      String,
        default:   CONTROLS_POSITION.DEFAULT,
        validator: value => Object.values(CONTROLS_POSITION).includes(value),
    },
    disabled:         {
        type: Boolean,
    },
});

const emit = defineEmits({
    input: null,
    focus: null,
    blur:  null,
});

const model = computed({
    get: () => props.value,
    set: (value) => {
        if (value === props.value) return;
        if (value < props.min) return emit('input', props.min);
        if (value > props.max) return emit('input', props.max);

        if (!props.stepStrictly) return emit('input', value);

        const strict = Math.round(value / props.step) * props.step;

        emit('input', strict);
    },
});

const input = debounce((value) => {
    if (value === '') {
        model.value = undefined;
        return;
    }

    const parsed = Number(value);

    if (isNaN(parsed)) return;

    model.value = parsed;
}, 150);

function handleFocus(event) {
    emit('focus', event);
}

function handleBlur(event) {
    emit('blur', event);
}

const decreaseDisabled = computed(() => props.disabled || (props.value - props.step) < props.min);

const {
    start: startDecrease,
    end:   endDecrease,
} = useRepeat(() => {
    model.value = (model.value ?? 0) - props.step;
}, decreaseDisabled);

const increaseDisabled = computed(() => props.disabled || (props.value + props.step) > props.max);

const {
    start: startIncrease,
    end:   endIncrease,
} = useRepeat(() => {
    model.value = (model.value ?? 0) + props.step;
}, increaseDisabled);

const sizeClass = computed(() => ({
    [SIZE.SMALL]:  'w-32',
    [SIZE.MEDIUM]: 'w-40',
    [SIZE.LARGE]:  'w-48',
}[props.size]));

const iconClass = computed(() => ({
    [SIZE.SMALL]:  'mx-1.5 w-4',
    [SIZE.MEDIUM]: 'mx-2 w-5',
    [SIZE.LARGE]:  'mx-2.5 w-6',
}[props.size]));

const getButtonClass = (disabled) => {
    if (disabled) {
        return [
            'flex items-center justify-center border transition-colors duration-100',
            'disabled:text-gray-200 disabled:bg-gray-500/5 disabled:cursor-not-allowed',
        ];
    }

    return [
        'flex items-center justify-center border transition-colors duration-100',
        'enabled:cursor-pointer bg-gray-500/5 hover:z-10 active:z-10',
        {
            [TYPE.DEFAULT]:       'text-gray-500 border-gray-400/40 hover:text-gray-600 hover:border-gray-400/80 active:text-science-blue-800 active:border-science-blue-800',
            [TYPE.PRIMARY]:       'text-primary-600/60 border-primary-600/40 hover:text-primary-600/80 hover:border-primary-600/80 active:text-primary-600 active:border-primary-600',
            [TYPE.SECONDARY]:     'text-secondary-950/60 border-secondary-950/40 hover:text-secondary-950/80 hover:border-secondary-950/80 active:text-secondary-950 active:border-secondary-950',
            [TYPE.PRIMARY_OLD]:   'text-science-blue-500/60 border-science-blue-500/40 hover:text-science-blue-500/80 hover:border-science-blue-500/80 active:text-science-blue-500 active:border-science-blue-500',
            [TYPE.SECONDARY_OLD]: 'text-science-blue-700/60 border-science-blue-700/40 hover:text-science-blue-700/80 hover:border-science-blue-700/80 active:text-science-blue-700 active:border-science-blue-700',
            [TYPE.SUCCESS]:       'text-emerald-600/60 border-emerald-600/40 hover:text-emerald-600/80 hover:border-emerald-600/80 active:text-emerald-600 active:border-emerald-600',
            [TYPE.WARNING]:       'text-orange-500/60 border-orange-500/40 hover:text-orange-500/80 hover:border-orange-500/80 active:text-orange-500 active:border-orange-500',
            [TYPE.DANGER]:        'text-red-600/60 border-red-600/40 hover:text-red-600/80 hover:border-red-600/80 active:text-red-600 active:border-red-600',
            [TYPE.INFO]:          'text-gray-500/60 border-gray-500/40 hover:text-gray-500/80 hover:border-gray-500/80 active:text-gray-500 active:border-gray-500',
        }[props.type],
    ];
};

const getOutsideButtonClass = (disabled) => {
    if (disabled) return 'text-gray-300 cursor-not-allowed flex items-center justify-center transition-colors duration-100';

    return [
        'cursor-pointer flex items-center justify-center transition-colors duration-100',
        {
            [TYPE.DEFAULT]:       'text-science-blue-800 active:text-science-blue-900',
            [TYPE.PRIMARY]:       'text-primary-600 active:text-primary-700',
            [TYPE.SECONDARY]:     'text-secondary-800 active:text-secondary-950',
            [TYPE.PRIMARY_OLD]:   'text-science-blue-500 active:text-science-blue-600',
            [TYPE.SECONDARY_OLD]: 'text-science-blue-700 active:text-science-blue-800',
            [TYPE.SUCCESS]:       'text-emerald-600 active:text-emerald-700',
            [TYPE.WARNING]:       'text-orange-500 active:text-orange-600',
            [TYPE.DANGER]:        'text-red-600 active:text-red-700',
            [TYPE.INFO]:          'text-gray-500 active:text-gray-600',
        }[props.type],
    ];
};
</script>

<template>
    <div
        v-if="!noControls && controlsPosition === CONTROLS_POSITION.OUTSIDE"
        class="flex items-stretch"
        :class="sizeClass"
    >
        <button
            :class="getOutsideButtonClass(decreaseDisabled)"
            :disabled="decreaseDisabled"
            @mousedown="startDecrease"
            @mouseup="endDecrease"
        >
            <base-input-icon-minus
                :class="iconClass"
            />
        </button>

        <base-input
            center
            :value="model"
            :name="name"
            :placeholder="placeholder"
            :size="size"
            :type="type"
            :disabled="disabled"
            @focus="handleFocus"
            @blur="handleBlur"
            @input="input"
        />

        <button
            :class="getOutsideButtonClass(increaseDisabled)"
            :disabled="increaseDisabled"
            @mousedown="startIncrease"
            @mouseup="endIncrease"
        >
            <base-input-icon-plus
                :class="iconClass"
            />
        </button>
    </div>
    <base-input
        v-else
        :value="model"
        prepend-class="flex items-center justify-center"
        append-class="flex items-center justify-center"
        center
        :class="sizeClass"
        :name="name"
        :placeholder="placeholder"
        :size="size"
        :type="type"
        :disabled="disabled"
        @focus="handleFocus"
        @blur="handleBlur"
        @input="input"
    >
        <template v-if="!noControls && controlsPosition === CONTROLS_POSITION.DEFAULT" #prepend>
            <button
                class="-mr-px h-full rounded-l"
                :class="getButtonClass(decreaseDisabled)"
                :disabled="decreaseDisabled"
                @mousedown="startDecrease"
                @mouseup="endDecrease"
            >
                <base-input-icon-minus
                    :class="iconClass"
                />
            </button>
        </template>

        <template v-if="!noControls" #append>
            <div
                v-if="controlsPosition === CONTROLS_POSITION.RIGHT"
                class="flex h-full flex-col"
                :class="[
                    {
                        [SIZE.SMALL]: 'w-6',
                        [SIZE.MEDIUM]: 'w-8',
                        [SIZE.LARGE]: 'w-10',
                    }[size]
                ]"
            >
                <button
                    class="-ml-px h-[calc(50%+1px)] rounded-tr"
                    :class="getButtonClass(increaseDisabled)"
                    :disabled="increaseDisabled"
                    @mousedown="startIncrease"
                    @mouseup="endIncrease"
                >
                    <base-input-icon-up class="w-3" />
                </button>
                <button
                    class="-mt-px -ml-px h-[calc(50%+1px)] rounded-br"
                    :class="getButtonClass(decreaseDisabled)"
                    :disabled="decreaseDisabled"
                    @mousedown="startDecrease"
                    @mouseup="endDecrease"
                >
                    <base-input-icon-down class="w-3" />
                </button>
            </div>

            <button
                v-if="controlsPosition === CONTROLS_POSITION.DEFAULT"
                class="-ml-px h-full rounded-r"
                :class="getButtonClass(increaseDisabled)"
                :disabled="increaseDisabled"
                @mousedown="startIncrease"
                @mouseup="endIncrease"
            >
                <base-input-icon-plus
                    :class="iconClass"
                />
            </button>
        </template>
    </base-input>
</template>
