import { MANAGER } from '@/store/modules/acl-access/constants';

export default [
    {
        name:      'manager',
        path:      '/manager/',
        component: () => import('@/pages/components/manager/manager'),
        redirect:  { name: 'managerMigration' },
        meta:      {
            layout: 'manager',
            title:  (route, { i18n }) => i18n.t('admin title'),
        },
        children:  [
            {
                name:      'managerMigration',
                path:      'migration/',
                component: () => import('@/pages/components/manager/migration'),
                meta:      {
                    acl:    MANAGER.MIGRATION_VIEW,
                    layout: 'manager',
                },
            },
            {
                name:      'managerTest',
                path:      'test/',
                component: () => import('@/pages/components/manager/test-records'),
                meta:      {
                    acl:    MANAGER.TEST_VIEW,
                    layout: 'manager',
                },

            },
            {
                name:      'managerMappingPresets',
                path:      'mapping/presets/',
                component: () => import('@/pages/components/manager/mapping-presets'),
                meta:      {
                    acl:    MANAGER.MAPPING_PRESET_VIEW,
                    layout: 'manager',
                },
            },
            {
                name:      'managerMatching',
                path:      'matching/',
                component: () => import('@/pages/components/manager/matching'),
                meta:      {
                    acl:    MANAGER.MATCHING_VIEW,
                    layout: 'manager',
                },
            },
            {
                name:      'managerBackup',
                path:      'backup/',
                component: () => import('@/pages/components/manager/backup'),
                meta:      {
                    acl:    MANAGER.BACKUP_LIST,
                    layout: 'manager',
                },
            },
            {
                name:      'managerEstimate',
                path:      'estimate/',
                component: () => import('@/pages/components/manager/estimate'),
                meta:      {
                    acl:    MANAGER.ESTIMATE_LIST,
                    layout: 'manager',
                },
            },
            {
                name:      'managerReplicator',
                path:      'replicator/',
                component: () => import('@/pages/components/manager/replicator'),
                meta:      {
                    layout: 'manager',
                },
            },
            {
                name:      'managerCalendah',
                path:      'calendah/',
                component: () => import('@/pages/components/manager/calendah'),
                meta:      {
                    acl:    MANAGER.CALENDAH_VIEW,
                    layout: 'manager',
                },
            },
            {
                name:      'managerCrm',
                path:      'crm/',
                component: () => import('@/pages/components/manager/crm'),
                meta:      {
                    acl:    MANAGER.CRM_VIEW,
                    layout: 'manager',
                },
            },
            {
                name:      'managerProcessTermination',
                path:      'process-termination/',
                component: () => import('@/pages/components/manager/process-termination'),
                meta:      {
                    acl:    MANAGER.PROCESS_TERMINATION_VIEW,
                    layout: 'manager',
                },
            },
            {
                name:      'managerProcessInstances',
                path:      'process-instances/',
                component: () => import('@/pages/components/manager/process-instances'),
                meta:      {
                    acl:    MANAGER.PROCESS_TERMINATION_VIEW,
                    layout: 'manager',
                },
            },
            {
                name:      'managerUser',
                path:      'user/',
                component: () => import('@/pages/components/manager/user'),
                meta:      {
                    acl:    MANAGER.USER_VIEW,
                    layout: 'manager',
                },
            },
            {
                name:      'managerSurveyAnswer',
                path:      'survey-answer/',
                component: () => import('@/pages/components/manager/survey-answer'),
                meta:      {
                    acl:    MANAGER.SURVEY_ANSWER_VIEW,
                    layout: 'manager',
                },
            },
            {
                name:      'managerAclAccess',
                path:      'acl-access/',
                component: () => import('@/pages/components/manager/acl-access'),
                meta:      {
                    acl:    MANAGER.ACL_ACCESS_VIEW,
                    layout: 'manager',
                },
            },
            {
                name:      'managerAclRole',
                path:      'acl-role/',
                component: () => import('@/pages/components/manager/acl-role'),
                meta:      {
                    acl:    MANAGER.ACL_ROLE_VIEW,
                    layout: 'manager',
                },
            },
            {
                name:      'managerAclRolesAccess',
                path:      'acl-roles-access/',
                component: () => import('@/pages/components/manager/acl-roles-access'),
                meta:      {
                    acl:    MANAGER.ACL_ROLES_ACCESS_VIEW,
                    layout: 'manager',
                },
            },
            {
                name:      'managerServicePlan',
                path:      'service-plan/',
                component: () => import('@/pages/components/manager/service-plan'),
                meta:      {
                    acl:    MANAGER.SERVICE_PLAN_VIEW,
                    layout: 'manager',
                },
            },
            {
                name:      'managerServicePayment',
                path:      'service-payment/',
                component: () => import('@/pages/components/manager/service-payment'),
                meta:      {
                    acl:    MANAGER.SERVICE_PAYMENT_VIEW,
                    layout: 'manager',
                },
            },
            {
                name:      'managerCoupon',
                path:      'coupon/',
                component: () => import('@/pages/components/manager/coupon'),
                meta:      {
                    acl:    MANAGER.COUPON_VIEW,
                    layout: 'manager',
                },
            },
            {
                name:      'managerMigrationPayment',
                path:      'migration-payment/',
                component: () => import('@/pages/components/manager/migration-payment'),
                meta:      {
                    acl:    MANAGER.MIGRATION_PAYMENT_VIEW,
                    layout: 'manager',
                },
            },
            {
                name:      'managerPayment',
                path:      'payment/',
                component: () => import('@/pages/components/manager/payment'),
                meta:      {
                    acl:    MANAGER.PAYMENT_VIEW,
                    layout: 'manager',
                },
            },
            {
                name:      'managerPaymentIPN',
                path:      'payment-ipn/',
                component: () => import('@/pages/components/manager/payment-ipn'),
                meta:      {
                    acl:    MANAGER.PAYMENT_IPN_VIEW,
                    layout: 'manager',
                },
            },
            {
                name:      'managerBalance',
                path:      'balance/',
                component: () => import('@/pages/components/manager/balance'),
                meta:      {
                    acl:    MANAGER.BALANCE_VIEW,
                    layout: 'manager',
                },
            },
            {
                name:      'managerPlatformCompare',
                path:      'platform/index/',
                component: () => import('@/pages/components/manager/platform-compare'),
                meta:      {
                    acl:    MANAGER.PLATFORM_COMPARE,
                    layout: 'manager',
                },
            },
            {
                name:      'managerPlatformMethods',
                path:      'platform/methods/',
                component: () => import('@/pages/components/manager/platform-methods'),
                meta:      {
                    acl:    MANAGER.PLATFORM_METHODS,
                    layout: 'manager',
                },
            },
            {
                name:      'managerPrices',
                path:      'prices/',
                component: () => import('@/pages/components/manager/prices'),
                meta:      {
                    acl:    MANAGER.PRICES_VIEW,
                    layout: 'manager',
                },
            },
            {
                name:      'managerApiDataCache',
                path:      'api-data-cache/',
                component: () => import('@/pages/components/manager/api-data-cache'),
                meta:      {
                    acl:    MANAGER.API_DATA_CACHE_VIEW,
                    layout: 'manager',
                },
            },
            {
                name:      'managerApiLog',
                path:      'api-log/',
                component: () => import('@/pages/components/manager/api-log'),
                meta:      {
                    acl:    MANAGER.API_LOG_VIEW,
                    layout: 'manager',
                },
            },
            {
                name:      'managerActivityLog',
                path:      'activity-log/',
                component: () => import('@/pages/components/manager/activity-log'),
                meta:      {
                    acl:    MANAGER.ACTIVITY_LOG_VIEW,
                    layout: 'manager',
                },
            },
            {
                name:      'managerLog',
                path:      'log/',
                component: () => import('@/pages/components/manager/log'),
                meta:      {
                    acl:    MANAGER.LOG_VIEW,
                    layout: 'manager',
                },
            },
            {
                name:      'managerEmail',
                path:      'email/',
                component: () => import('@/pages/components/manager/email'),
                meta:      {
                    acl:    MANAGER.EMAIL_VIEW,
                    layout: 'manager',
                },
            },
            {
                name:      'managerEvent',
                path:      'event/',
                component: () => import('@/pages/components/manager/event'),
                meta:      {
                    acl:    MANAGER.EVENT_VIEW,
                    layout: 'manager',
                },
            },
            {
                name:      'managerJira',
                path:      'jira/',
                component: () => import('@/pages/components/manager/jira'),
                meta:      {
                    acl:    MANAGER.JIRA_VIEW,
                    layout: 'manager',
                },
            },
            {
                name:      'managerReportMigration',
                path:      'report/migration/',
                component: () => import('@/pages/components/manager/migration-report'),
                meta:      {
                    acl:    MANAGER.REPORT_MIGRATION,
                    layout: 'manager',
                },
            },
            {
                name:      'managerReportSales',
                path:      'report/sales/',
                component: () => import('@/pages/components/manager/sales-report'),
                meta:      {
                    acl:    MANAGER.REPORT_SALES,
                    layout: 'manager',
                },
            },
        ],
    },
];
