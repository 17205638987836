<template>
    <base-popover
        placement="bottom"
        trigger="click"
        render="lazy"
    >
        <template #reference>
            <base-button
                custom-type-class="text-white hover:bg-white/10 active:bg-white/5"
                content-class="hidden text-sm lowercase lg:inline-block"
                size="small"
                text
                :label="email"
                :icon="{ name: 'user-circle' }"
                :data-test="dataTest('user-button')"
            />
        </template>

        <template #default="{ close }">
            <header-navigation @navigate="close" />
        </template>
    </base-popover>
</template>

<script>
import { mapGetters } from 'vuex';
import HeaderNavigation from '@/components/app/header/navigation';

export default {
    name:       'HeaderUser',
    components: { HeaderNavigation },
    computed:   {
        ...mapGetters({
            userEmail: 'user/getEmail',
        }),
        email() {
            return this.userEmail || this.$t('header user guest');
        },
    },
};
</script>
