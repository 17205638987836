const prefix = 'fac';
const iconName = 'vacuum';
const width = 512;
const height = 512;
const ligatures = [];
const unicode = null;
const svgPathData = 'M236 52C188.174 52 148.268 85.9135 139.02 131.004L139 131 112.198 256.538C111.268 260.895 114.589 265 119.044 265H160.076C163.379 265 166.233 262.692 166.922 259.462L191.786 143H191.825C196.051 120.237 216.012 103 240 103H412C439.062 103 461 124.938 461 152V155C461 172.051 452.29 187.069 439.077 195.845 411.4 169.893 374.178 154 333.243 154 304.39 154 281 177.39 281 206.243V271.44C281 277.99 287.207 282.76 293.7 281.903 298.216 281.307 302.822 281 307.5 281 365.214 281 412 327.786 412 385.5 412 407.251 405.355 427.45 393.983 444.177 388.799 451.804 393.773 464 402.994 464H435C464.271 464 488 440.271 488 411V308.757C488 282.612 481.517 257.982 470.07 236.385 495.419 218.671 512 189.272 512 156V150C512 95.8761 468.124 52 414 52H236ZM157.315 304.462C158.245 300.105 154.923 296 150.469 296H109.436C106.133 296 103.28 298.309 102.59 301.538L84.9852 384H64.4882C28.8723 384 0 412.872 0 448.488 0 455.951 6.0494 462 13.5118 462H141C162.539 462 180 444.539 180 423 180 401.461 162.539 384 141 384H140.334L157.315 304.462ZM307 464C350.078 464 385 429.078 385 386 385 342.922 350.078 308 307 308 263.922 308 229 342.922 229 386 229 429.078 263.922 464 307 464ZM334 386C334 400.912 321.912 413 307 413 292.088 413 280 400.912 280 386 280 371.088 292.088 359 307 359 321.912 359 334 371.088 334 386Z';

export const definition = {
    prefix,
    iconName,
    icon: [
        width,
        height,
        ligatures,
        unicode,
        svgPathData,
    ],
};

export { definition as faVacuum };
