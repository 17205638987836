import Vue from 'vue';
import useComponents from '@/use/components';
import useKnowledgeBase from '@/use/knowledge-base';
import useRouter from '@/use/router';
import useStore from '@/use/store';
import usePlugins from '@/use/plugins';
import useManager from '@/use/manager';

Vue.prototype.$appModules ??= new Map();

const uses = {
    components:    useComponents,
    knowledgeBase: useKnowledgeBase,
    router:        useRouter,
    store:         useStore,
    plugins:       usePlugins,
    manager:       useManager,
};

export const useModule = useList => ({ store, router }, { name, module }) => {
    if (Vue.prototype.$appModules.has(name)) return;

    const useData = Object.entries(useList).reduce((moduleData, [prop, callback]) => {
        if (!module.use?.[prop]) return moduleData;

        moduleData[prop] = callback({ store, router }, name, module.use?.[prop]);

        return moduleData;
    }, {});

    const registerData = {
        ...module,
        use: useData,
    };

    Vue.prototype.$appModules.set(name, registerData);

    return registerData;
};

export default ({ store, router }, modules) => {
    const imported = modules.map(({ NAME: name, default: module }) => {
        try {
            const registeredData = useModule(uses)({ store, router }, { name, module });

            if (!registeredData) return null;

            if (!import.meta.env.PROD) {
                // eslint-disable-next-line no-console
                console.info(`Register app module: ${name}`);
            }

            return name;
        } catch (e) {
            // eslint-disable-next-line no-console
            console.error(`Registering app module error: ${name}\n${e.toString()}`);

            return null;
        }
    }).filter(Boolean);

    if (imported.length) {
        window.emitter.emit('app-modules');
    }
};
