import Vue from 'vue';
import mitt from 'mitt';
import { mapState } from 'vuex';
import App from '@/app';
import createRouter from '@/router';
import store from '@/store';
import usePlugins from '@/plugins';
import hideLoader from '@/assets/util/loader';
import registerVersionWorker from '@/workers/register-version-worker';
import authorizationWrapper from '@/components/authorization-wrapper';
import generalError from '@/components/general-error/general-error';
import useModules from '@/use/modules';
import { APP } from '@/assets/util/event-constants';
import '@/components/base-components';
import '@/assets/libs/helpers';
import '@/assets/style/tailwind.css';
import { SECTION_ASSET_ESTIMATE } from '@/modules/estimate/router.js';
import { ESTIMATE, REPLICATOR } from '@/modules/list';

Vue.config.productionTip = false;
Vue.config.performance = !import.meta.env.PROD;

window.eventBus = new Vue();
window.emitter = mitt();

const options = {
    headers: {
        'X-Requested-With': 'XMLHttpRequest',
    },
};

const isProductAllowed = (list, name, defaultValue = 'deny') => ['all', 'my'].includes(list?.[name] ?? defaultValue);

async function importModules({ router, store, aclAccess }) {
    const modules = [
        import('@/modules/calendah'),
        import('@/modules/connection'),
        import('@/modules/knowledge-base'),
        import('@/modules/mapping'),
        import('@/modules/matching'),
        import('@/modules/monday'),
    ];

    if (isProductAllowed(aclAccess, ESTIMATE)
        || isProductAllowed(aclAccess, SECTION_ASSET_ESTIMATE)
    ) {
        modules.push(import('@/modules/estimate'));
    }

    if (isProductAllowed(aclAccess, REPLICATOR)) {
        modules.push(import('@/modules/replicator'));
    }

    useModules({ router, store }, await Promise.all(modules));
}

fetch('/config', options).then(response => response.json()).then(async (config) => {
    const { i18n, pinia } = usePlugins(Vue, { store, config });
    const router = createRouter({ store, i18n, config });

    await importModules({ router, store, aclAccess: config.window?.aclAccess });

    window.config = config.window;

    await store.dispatch('aclAccess/SET_ACCESSES', config.window.aclAccess);
    await store.dispatch('user/SET_DATA', { data: config.user });

    const app = new Vue({
        pinia,
        router,
        store,
        components: {
            authorizationWrapper,
            generalError,
        },
        created() {
            if (import.meta.env.PROD) {
                window.emitter.on(APP.UPDATE, (updated) => {
                    if (!updated) return;

                    store.dispatch('SET_VERSION_ACTUAL', false);
                });

                registerVersionWorker();
            }

            this.$socket.on(APP.MAINTENANCE, (message) => {
                const { mode } = JSON.parse(message);

                store.dispatch('SET_MAINTENANCE', mode);
            });

            if (window.config.cobranding?.value) {
                document.getElementById('app-favicon').href = `${import.meta.env.BASE_URL}img/cobranding/${window.config.cobranding.value}/favicon.svg`;
            }

            hideLoader();
        },
        computed: {
            ...mapState('aclAccess', ['accesses']),
        },
        watch:    {
            accesses(aclAccess) {
                importModules({ router, store, aclAccess });
            },
        },
        i18n,
        render:   h => h(App),
    }).$mount('#app');

    if (window.Cypress) {
        // Only available during E2E tests
        window.app = app;
    }
});
