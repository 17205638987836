<template>
    <product-list-item-wrapper
        :route="{ name: 'migrationEdit', params: { migrationId: migration.id } }"
        :disabled="!migration.canViewWizard"
        :go-message="$t('migration wizard button')"
        wrap-class="grid min-w-0 gap-1 text-xs sm:text-sm md:grid-cols-12 md:text-xs lg:gap-x-4 lg:gap-y-4 lg:text-sm"
    >
        <div class="col-span-12 flex flex-col space-y-1 sm:space-y-0 sm:flex-row lg:col-span-9 xl:space-x-2 xl:col-span-6 xl:flex-row">
            <div
                class="flex flex-1 shrink-0 items-center truncate font-medium text-gray-500"
                :class="{
                    'sm:flex-col sm:justify-center sm:items-start gap-y-1': hasNameAccess,
                }"
            >
                <span class="px-2 sm:mt-1">
                    {{ $t('migration number') }}: {{ migration.id }}
                </span>
                <item-name
                    v-if="hasNameAccess"
                    :name="migration.name"
                    @rename="rename"
                />
            </div>
            <div class="flex flex-1 items-center justify-between px-4 md:justify-around 2xl:space-x-6 2xl:justify-center">
                <div class="my-auto shrink-0 py-1">
                    <crm-logo-link
                        v-if="migration.source.type"
                        class="flex-1 overflow-hidden"
                        :crm="migration.source.type"
                        :name="migration.source.name"
                        :url="migration.source.url"
                        size="sm"
                        auto-size
                    />
                </div>
                <svg v-if="migration.target.type" class="mx-4 h-5 shrink-0 text-gray-400" xmlns="http://www.w3.org/2000/svg"
                     fill="none" viewBox="0 0 24 24" stroke="currentColor"
                >
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M14 5l7 7m0 0l-7 7m7-7H3" />
                </svg>
                <div class="my-auto shrink-0 py-1">
                    <crm-logo-link
                        v-if="migration.target.type"
                        class="flex-1 overflow-hidden"
                        :crm="migration.target.type"
                        :name="migration.target.name"
                        :url="migration.target.url"
                        size="sm"
                        auto-size
                    />
                </div>
            </div>
        </div>
        <div class="col-span-9 flex items-center md:col-span-8 lg:order-last lg:col-span-9 xl:order-none xl:col-span-4">
            <product-list-item-info
                class="gap-2 lg:grid-cols-2 lg:gap-4 xl:grid-cols-1"
                :list="infoItems"
            />
        </div>
        <div class="col-span-3 flex items-center justify-center md:col-span-4 lg:col-span-3 lg:row-span-2 xl:col-span-2 xl:row-span-1">
            <template v-if="isDetailsAvailable">
                <base-button
                    class="hidden sm:block"
                    size="small"
                    round
                    :data-test="dataTest(`grid-row-${migration.id}-details`)"
                    @click.prevent.stop="viewDetails"
                >
                    {{ $t('migration view details button') }}
                </base-button>
                <base-button
                    class="sm:hidden"
                    type="secondary"
                    link
                    :icon="{ name: 'list-ul' }"
                    :data-test="dataTest(`grid-row-${migration.id}-details`)"
                    @click.prevent.stop="viewDetails"
                />
            </template>
        </div>
    </product-list-item-wrapper>
</template>

<script>
import { isEmpty, kebabCase } from 'lodash';
import { mapActions, mapGetters } from 'vuex';
import notify from '@/components/base-components/components/notification/notify';
import { MIGRATION } from '@/store/modules/acl-access/constants';
import ProductListItemWrapper from '@/components/app/product/list/item-wrapper';
import ProductListItemInfo from '@/components/app/product/list/item-info';
import CrmLogoLink from '@/components/crm-logo-link';
import ItemName from '@/components/app/product/item/item-name';
import { migrationRename } from '@/components/migration/api.js';

export default {
    name:       'MigrationListItem',
    components: {
        ProductListItemWrapper,
        ProductListItemInfo,
        CrmLogoLink,
        ItemName,
    },
    props:      {
        migration: {
            type:     Object,
            required: true,
        },
    },
    data() {
        return {
            statusColors: {
                source:              'bg-red-400',
                target:              'bg-teal-500',
                'demo-fail':         'bg-red-400',
                'full-fail':         'bg-red-400',
                start:               'bg-yellow-400',
                package:             'bg-blue-500',
                entities:            'bg-blue-500',
                matching:            'bg-blue-500',
                mapping:             'bg-blue-500',
                'demo-stopped':      'bg-gray-400',
                'full-stopped':      'bg-gray-400',
                pause:               'bg-gray-400',
                'demo-wait':         'bg-yellow-300',
                'full-wait':         'bg-yellow-300',
                request:             'bg-yellow-300',
                'demo-complete':     'bg-emerald-400',
                'full-complete':     'bg-emerald-400',
                'demo-in-progress':  'bg-emerald-400',
                'full-in-progress':  'bg-emerald-400',
                'pre-package':       'bg-blue-700',
                'full-limit':        'bg-blue-600',
                'demo-limit':        'bg-blue-600',
                'demo-unauthorized': 'bg-red-600',
                'full-unauthorized': 'bg-red-600',
                'full-paused':       'bg-amber-500',
                'demo-paused':       'bg-amber-500',
                options:             'bg-azure-500',
            },
            popoverText:  this.$t('migration wizard insurance button tooltip', {
                linkAboutMigrationInsuranceOpen:  '<a href="https://www.data2crm.com/migration/crm-migration-insurance" target="_blank">',
                linkAboutMigrationInsuranceClose: '</a>',
                linkContactOpen:                  '<a href="https://www.data2crm.com/migration/#callback_form" target="_blank">',
                linkContactClose:                 '</a>',
            }),
        };
    },
    computed: {
        ...mapGetters({
            isGranted: 'aclAccess/isGranted',
        }),
        hasNameAccess() {
            return this.isGranted(MIGRATION.NAME);
        },
        infoItems() {
            return [
                {
                    condition: true,
                    icon:      {
                        class: [
                            this.statusColors[kebabCase(this.migration.status)],
                            'w-4 h-4 rounded-full',
                        ],
                    },
                    value:     this.$t(`migration status ${this.migration.status}`),
                },
                {
                    condition: this.migration.updatedAt,
                    icon:      {
                        name:  'calendar-check',
                        class: 'text-gray-300',
                    },
                    label:     this.$t('updated'),
                    value:     this.$filters.formatDate(this.migration.updatedAt, {
                        month: 'long',
                        day:   'numeric',
                        year:  'numeric',
                    }),
                },
            ].filter(item => item.condition);
        },
        isDetailsAvailable() {
            return !isEmpty(this.migration.source) && !isEmpty(this.migration.target);
        },
    },
    methods:  {
        ...mapActions({
            showLoader: 'loader/SHOW',
            hideLoader: 'loader/HIDE',
        }),
        viewDetails() {
            this.$router.push({ name: 'migrationDetails', params: { migrationId: this.migration.id } });
        },
        async rename(name) {
            await this.showLoader();

            const oldName = this.migration.name;

            this.$set(this.migration, 'name', name);

            try {
                await migrationRename(this.migration.id, name);
            } catch ({ message }) {
                this.$set(this.migration, 'name', oldName);

                notify.error({
                    title: this.$t('request error notification'),
                    message,
                });
            } finally {
                await this.hideLoader();
            }
        },
    },
};
</script>
