<template>
    <header :data-test="dataTest()">
        <div class="flex h-12 items-center justify-between gap-x-4 pr-2">
            <app-sidebar-button class="ml-4" />
            <header-breadcrumbs class="flex-1" />
            <div class="flex items-center gap-x-2">
                <router-link
                    :to="{ name: 'help' }"
                    class="text-white hover:text-primary-600 focus:text-primary-600 active:text-primary-600"
                >
                    <base-icon
                        name="question-circle"
                        prefix="far"
                    />
                </router-link>

                <div class="flex shrink-0 items-center justify-end space-x-2 md:hidden">
                    <button
                        class="inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-100/10 hover:text-gray-50 focus:outline-none"
                        @click="showMobileMenu = !showMobileMenu"
                    >
                        <svg
                            class="h-6 w-6" stroke="currentColor" fill="none" viewBox="0 0 24 24"
                            :class="showMobileMenu ? 'hidden' : 'block'"
                        >
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16" />
                        </svg>
                        <svg
                            class="h-6 w-6" stroke="currentColor" fill="none" viewBox="0 0 24 24"
                            :class="showMobileMenu ? 'block' : 'hidden'"
                        >
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                        </svg>
                    </button>
                </div>
                <div class="hidden shrink-0 space-x-4 md:flex md:items-center md:justify-end">
                    <header-login v-if="!userExists" />
                    <header-user v-else />
                </div>
            </div>
        </div>
        <div v-if="showMobileMenu" class="bg-white md:hidden">
            <header-navigation />
        </div>
    </header>
</template>

<script>
import { mapGetters } from 'vuex';
import AppSidebarButton from '@/components/app/sidebar/button.vue';
import HeaderBreadcrumbs from '@/components/app/header/breadcrumbs';
import HeaderLogin from '@/components/app/header/navigation/login';
import HeaderUser from '@/components/app/header/user';
import HeaderNavigation from '@/components/app/header/navigation';

export default {
    name:       'AppHeader',
    components: {
        AppSidebarButton,
        HeaderLogin,
        HeaderUser,
        HeaderBreadcrumbs,
        HeaderNavigation,
    },
    data() {
        return {
            showMenu:       false,
            showMobileMenu: false,
            showAddFunds:   false,
        };
    },
    computed: {
        ...mapGetters({
            userExists: 'user/isExists',
            userEmail:  'user/getEmail',
        }),
        email() {
            return this.userEmail || this.$t('header user guest');
        },
    },
};
</script>
