<script>
export default {
    name: 'ItemName',
};
</script>

<script setup>
import { ref, watch } from 'vue';

const props = defineProps({
    name:     {
        type: String,
    },
    disabled: {
        type: Boolean,
    },
});

const emit = defineEmits({
    rename: null,
});

const edit = ref(false);

async function click() {
    if (props.disabled) return;

    edit.value = true;
}

const newName = ref(props.name || '');

watch(() => props.name, () => {
    newName.value = props.name;
});

function save() {
    edit.value = false;
    emit('rename', newName.value);
}
</script>

<template>
    <div v-if="!disabled || name" class="h-7 max-w-full">
        <base-input
            v-if="edit && !disabled"
            v-model="newName"
            size="small"
            :maxlength="50"
            :placeholder="$t('add name')"
            clearable
            autofocus
            @clear="newName = null"
            @blur="edit = false"
            @change="save"
        />
        <div
            v-else
            class="inline-flex h-full w-full items-center gap-x-1 rounded px-2 transition-colors duration-150"
            :class="{
                'cursor-pointer hover:bg-gray-100': !disabled,
            }"
            @click.prevent.stop="click"
        >
            <div class="inline-block truncate">
                <span v-if="name" :title="name.length > 10 ? name : undefined">
                    {{ name }}
                </span>
                <span v-else class="text-gray-400 transition-colors duration-150 group-hover:text-gray-500">
                    {{ $t('add name') }}
                </span>
            </div>
            <base-icon
                v-if="!disabled"
                class="text-gray-500 opacity-0 transition-opacity duration-150 group-hover:opacity-100"
                name="pencil-alt"
                size="sm"
            />
        </div>
    </div>
</template>
