<script>
export default {
    name: 'AppSidebarButton',
};
</script>

<script setup>
import { computed } from 'vue';
import { useStore } from '@/store/index.js';

const store = useStore();

const collapsed = computed(() => store.state.sidebar.collapsed);

function toggleSidebar() {
    store.dispatch('sidebar/TOGGLE');
}
</script>

<template>
    <div
        class="flex h-6 w-6 cursor-pointer items-center justify-center rounded-full text-white/40 ring-1 ring-white/40 transition-all ease-in-out bg-secondary-950 hover:text-white/90 hover:ring-white/90 active:bg-secondary-800 active:ring-secondary-800"
        :class="collapsed ? 'rotate-180' : 'rotate-0'"
        @click="toggleSidebar"
    >
        <base-icon
            class="h-3 w-3"
            name="chevron-left"
            prefix="fas"
        />
    </div>
</template>
