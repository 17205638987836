const prefix = 'fac';
const iconName = 'unite';
const width = 512;
const height = 262;
const ligatures = [];
const unicode = null;
const svgPathData = 'M0 26C0 11.6406 11.6406 0 26 0H210C224.359 0 236 11.6406 236 26C236 40.3594 224.359 52 210 52H26C11.6406 52 0 40.3594 0 26ZM276 236C276 221.641 287.641 210 302 210H486C500.359 210 512 221.641 512 236C512 250.359 500.359 262 486 262H302C287.641 262 276 250.359 276 236ZM164 105C149.641 105 138 116.641 138 131C138 145.359 149.641 157 164 157H348C362.359 157 374 145.359 374 131C374 116.641 362.359 105 348 105H164Z';

export const definition = {
    prefix,
    iconName,
    icon: [
        width,
        height,
        ligatures,
        unicode,
        svgPathData,
    ],
};

export { definition as faUnite };
