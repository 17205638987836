const defaultOptions = {
    precision: 2,
    strict:    false,
    currency:  'USD',
    locale:    'en-EN',
};

/**
 * Format currency based on Intl.NumberFormat
 * @link https://developer.mozilla.org/ru/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat
 *
 * @param {Number} value
 * @param {Object} options
 */
const formatCurrency = (
    value,
    options = {},
) => {
    if (value === null || value === undefined) return value;

    const { precision, strict, currency, locale } = {
        ...defaultOptions,
        ...options,
    };

    return new Intl.NumberFormat(locale, {
        style:                 'currency',
        minimumFractionDigits: precision,
        maximumFractionDigits: strict ? precision : undefined,
        currency,
    }).format(value);
};

export default formatCurrency;
